<template>
  <div class="text-center">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="text-left ml-4">Company Objective Evidence List</h3>
      </div>
      <div class="col-md-auto">
        <label class="col-form-label pr-2 font-weight-bold">Filter By Year: </label>
      </div>
      <div class="col-md-auto px-1">
        <select name="month" id="month" class="form-control form-control-sm" v-model="oeYear">
          <option :value="year" v-for="year in years">{{year}}</option>
        </select>
      </div>
      <div class="col-3">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" placeholder="Search Objective Evidence">
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th style="width: 10%" class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('category')">
              CATEGORY
              <sorter-arrow
                :keyInList="'category'"
                :array-list-length="objectiveEvidencesByCategory.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
            </th>
            <th class="align-middle no-select cursor-pointer" scope="col">
              OBJECTIVE EVIDENCE
            </th>
            <th width="10%" class="align-middle no-select cursor-pointer">
              COUNT
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>
          <template v-else>
            <tr v-if="objectiveEvidencesByCategory.length === 0">
              <td colspan="100%" class="align-middle text-center font-weight-bold bg-secondary text-white">NO RESULTS FOUND</td>
            </tr>
            <template v-else>
              <tr v-for="(data, oeIndex) in objectiveEvidencesByCategory"
                  @dblclick="setOeSelectedIndex(oeIndex)"
                  class="oe-list cursor-pointer no-select">
                <td>
                  {{ data.category }}
                </td>
                <td>


                    <span>
                                      <o-e-title-viewer
                                        :objective-evidences="data.objective_evidences"
                                        :category="data.category"
                                      />
                  </span>
                </td>
                <td class="align-middle">
                  {{ data.objective_evidences.length }}
                </td>
              </tr>
            </template>
          </template>

          </tbody>
        </table>
        <view-objective-evidences
          v-if="OECategoryIndex !== null"
          :objective-evidences-by-category="objectiveEvidencesByCategory[OECategoryIndex]"
          @close="OECategoryIndex = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import {permissionMixin} from "@/mixins/permissionMixins";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import SorterArrow from "@/components/common/SorterArrow.vue";
import SorterArrowMixin from "@/mixins/SorterArrowMixin";
import _ from 'lodash';
import {mapActions, mapGetters} from "vuex";
import CompanyViewObjectiveEvidences from "@/components/modules/ia/company/CompanyViewObjectiveEvidences.vue";
import OETitleViewer from "@/components/modules/ia/OETitleViewer.vue";

export default {
  mixins: [vueAppMixin, permissionMixin, PaginationMixin, ImageSliderMixin, SorterArrowMixin],
  components: {AppLoading, ImageSlider, SorterArrow, CompanyViewObjectiveEvidences, OETitleViewer},

  name: 'CompanyObjectiveEvidenceList',
  data() {
    return {
      loading: false,
      selectedImageIndex: 0,
      objectiveEvidencesByCategory: [],
      objectiveEvidenceIndex: null,
      changeValueForRendering: false,
      showSlider: false,
      imagesToSlide: {
        before: [],
        after: []
      },
      OECategoryIndex: null,
      years: Array.from({length: 100}, (_, i) => new Date().getFullYear() - i),
      oeYear: new Date().getFullYear(),
    }
  },
  methods: {
    ...mapActions([
      'getCompaniesLatestObjectiveEvidences'
    ]),
    setOeSelectedIndex(index) {
      this.OECategoryIndex = index;
    },
    sorterArrowSetDirection(orderBy) {
      this.currentOrderBy = orderBy;
      this.$emit('sorterArrowSetDirection', orderBy);
    },
    runCallBack(param) {
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.objectiveEvidencesByCategory = _.orderBy(this.objectiveEvidencesByCategory, [param.orderBy], [param.direction]);
    },
    async setOeToTable() {
      this.loading = true;
      await this.getCompaniesLatestObjectiveEvidences(this.oeYear);
      this.objectiveEvidencesByCategory = this.companyLatestOeLists;
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters([
      'companyLatestOeLists'
    ]),
  },
  async created() {
    this.setOEList = _.debounce(this.setOeToTable, 10);
    await this.setOeToTable();

  },
  watch: {
    'oeYear'() {
      this.setOEList()
    },
  },
}
</script>

<style scoped lang="scss">
.oe-list:hover {
  background-color: #dae8f4;
}
</style>
