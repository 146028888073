<template>
<div>
  <div role="button" :id= "'popover-container_' + id" class="popover-container" data-toggle="popover" data-trigger="hover" :title="`Objective Evidence Titles for ${category}`" data-html="true"  data-placement="right">
    <template v-if="objectiveEvidences.length > 0">
      <strong v-for="x in objectiveEvidences"> x</strong>
    </template>
  </div>
</div>
</template>

<script>
export default {
  name : 'OETitleViewer',
  props : {
    objectiveEvidences :{
      type : Array,
      default : []
    },
    category : {
      type : String,
      default : ''
    },
    id : {
      type : Number,
      default : 0
    },
  },
  methods : {
    concatDash() {

    },
    getContent() {
      let content = ``;

      this.objectiveEvidences.forEach(oe => {
        content += `<ul><li class="ml-3">${oe.description}</li></ul>`;
      });

      return content;
    }
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  mounted() {
    const popoverContainer = $('#popover-container_' + this.id);
    popoverContainer.popover({
      trigger : 'manual',
      content: `<div id="custom-popover_${this.id}" class="custom-popover">` +
        this.getContent() +
        `</div>`
    });

    popoverContainer.on('mouseover', function() {
      var _this = this;
      const thisId = $(this).attr('id');
      const allPopoverContainer = $('.popover-container');
      allPopoverContainer.each(index => {
        const popoverContainer = $(allPopoverContainer[index]);
        const popoverAttrId = popoverContainer.attr('id');
        if (popoverAttrId === thisId) {
          $(popoverContainer).popover('show');
        } else {
          $(popoverContainer).popover('hide');
        }
      });
      $('.popover').on('mouseleave', function () {
        $(_this).popover('hide');
      });
    })
      .on('mouseleave', function() {
      var _this = this;
      setTimeout(function() {
        if (!$('.popover:hover').length) {
          $(_this).popover('hide');
        }
      }, 2);
    });
  },
}
</script>

<style>

.custom-popover {
  width: 100%; /* Set the desired width */
  max-height: 150px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.popover {
  max-width: 40vw !important;
  //margin-top: -1px;
  width: 100%;
}

</style>
